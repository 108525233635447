import { render, staticRenderFns } from "./SubscribeTipDialog.vue?vue&type=template&id=4c9c051c&scoped=true&"
import script from "./SubscribeTipDialog.vue?vue&type=script&lang=js&"
export * from "./SubscribeTipDialog.vue?vue&type=script&lang=js&"
import style0 from "./SubscribeTipDialog.vue?vue&type=style&index=0&id=4c9c051c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4c9c051c",
  null
  
)

export default component.exports