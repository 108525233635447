<template>
  <el-dialog
    :visible="visible"
    :title="title"
    width="468px"
    append-to-body
    custom-class="subscribel-tip-dialog"
    @close="close"
  >
    <div
      class="content"
    >
      {{ content }}
    </div>
    <span
      v-if="showFooter"
      slot="footer"
      class="dialog-footer"
    >
      <el-button
        class="subscribe-dialog-btn"
        size="mini"
        @click="close"
      >取消</el-button>
      <el-button
        v-if="dialogType==='apply'"
        v-sensor:click="{
          $element_id: 'live_application',
          consumer_id: profile.uid,
          room_id: roomId,
        }"
        class="subscribe-dialog-btn"
        size="mini"
        type="primary"
        @click="applyWatchLive"
      >申请观看</el-button>
      <el-button
        v-if="dialogType==='certify'"
        class="subscribe-dialog-btn"
        size="mini"
        type="primary"
        @click="certifyLive"
      >去认证</el-button>
    </span>
  </el-dialog>
</template>
<script>
import { mapState } from 'vuex';
import { applyLive } from '@/services/room';
import { environmentHref, cloudRequestPath } from '@/utils/cloudRequest';
import { getCloudToken } from '@/services/home';

export default {
  props: {
    // 订阅弹框分为申请观看和立即认证两种类型
    dialogType: {
      type: String,
      default: 'apply',
    },
    roomId: {
      type: [String, Number],
      default: '',
    },
    // 是否展示底部操作按钮
    showFooter: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '提示',
    },
    visible: {
      type: Boolean,
      default: false,
    },
    content: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapState('user', [
      'profile',
    ]),
  },
  methods: {
    close() {
      this.$emit('update:visible', false);
    },
    // 申请观看
    async applyWatchLive() {
      try {
        const res = await applyLive({
          id: this.roomId,
        });
        this.close();
        if (res?.code === 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success(res);
        this.$emit('updateLists');
      } catch (error) {
        // console.log(error)
      }
    },
    // 认证创业者身份
    async certifyLive() {
      this.close();
      try {
        const token = await getCloudToken();
        const fullPath = `${environmentHref}/#/financing/personalCenter`;
        window.open(`${cloudRequestPath}&token=${token}&redirectUrl=${fullPath}`);
      } catch (error) {
        // console.log(error)
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.content {
  padding: 32px 0;
  font-size: 14px;
  color: #333b44;
  letter-spacing: 0;
  line-height: 20px;
}

.subscribe-dialog-btn {
  width: 80px;
  text-align: center;
}
</style>
